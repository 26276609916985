import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { css, Global } from '@emotion/core'
import firebase from 'firebase/app'
import 'firebase/storage'
import Footer from '../Footer'
import './index.css'


const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
}

// initailize if firebase is not initialized before
if (!firebase.apps.length) {
  firebase.initializeApp(config)
}

// use the state here, change the header based on the user's state
export default ({ children }) => (
  <main>
    <Global
      styles={css`
        body {
          background-color: #f4f4f4;
          color: #30516b;
        }

        @media only screen and (max-width: 640px) and (orientation: portrait) {
          input, .text-sm {
            font-size: 16px !important;
          }
        }

        @media only screen and (max-width: 1000px) and (orientation: landscape) {
          input, .text-sm {
            font-size: 16px !important;
          }
        }
      `}
    />
    {children}
    <ToastContainer />
    <Footer />
  </main>
)
