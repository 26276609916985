import React from 'react'
import { css } from '@emotion/core'
import { connect } from 'react-redux'
import { Link } from 'gatsby'

import { getLanguage } from '../../../store/data'
import localization from '../../../constants/localization'
import whiteLogo from '../../../assets/images/white_logo.svg'

const LogoWithSpan = ({ language, content = 'liveBetterAndSave' }) => (
  <Link to="/" className="no-underline">
    <div className="flex flex-col items-center">
      <img
        src={whiteLogo}
        css={css`
          width: 12.5rem;

          @media only screen and (max-width: 640px) {
            margin-top: 4rem;
          }
        `}
        alt="logo"
      />
      <div className="text-white text-lg mt-2">
        {localization.get(language, content)}
      </div>
    </div>
  </Link>
)

const mapStateToProps = state => ({
  language: getLanguage(state),
})

export default connect(mapStateToProps, {})(LogoWithSpan)
