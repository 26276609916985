// /* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import Button from 'react-bootstrap/Button'
import { Link } from 'gatsby'
import { css } from '@emotion/core'

import localization from '../../../constants/localization'

export const CustomButton = ({ className = '', content, ...props }) => (
  <Button
    variant="light"
    className={`text-white text-base h-8 px-4 py-0 rounded-full transition-all transition-250 hover:opacity-75 disabled:bg-gray-2 ${className} ${
      'disabled' in props && props.disabled === true ? 'cursor-not-allowed' : ''
      }`}
    {...props}
  >
    {content}
  </Button>
)

export const SignupButton = ({ className = '', language }) => (
  <Link
    to="/app/signup"
    className={`text-white bg-green-1 text-sm h-8 flex items-center px-6 rounded-full no-underline transition-all transition-250 hover:opacity-75 ${className}`}
  >
    {localization.get(language, 'signup')}
  </Link>
)

export const LinearButton = ({ className = '', children, ...props }) => (
  <Button
    variant="light"
    css={css`
      background: linear-gradient(to right, #7fc5f9, #38a0f0);
    `}
    className={`text-white text-base h-10 px-6 sm:px-8 shadow-lg border-none rounded-full transition-all transition-250 hover:opacity-75 disabled:bg-gray-2 ${className} ${
      'disabled' in props && props.disabled === true ? 'cursor-not-allowed' : ''
      }`}
    {...props}
  >
    {children}
  </Button>
)

export const RoundButton = ({ className = '', children, ...props }) => (
  <Button
    css={css`
      position: relative;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
    `}
    className={` bg-white rounded-full border-gray-200 shadow-lg ${className} `}
    {...props}
  >
    {children}
  </Button>
)

export const LinearButtonHeightAuto = ({ className = '', children, ...props }) => (
  <Button
    variant="light"
    css={css`
      background: linear-gradient(to right, #7fc5f9, #38a0f0);
      min-height: 2.5rem;
    `}
    className={`text-white text-base px-6 sm:px-8 shadow-lg border-none rounded-full transition-all transition-250 hover:opacity-75 disabled:bg-gray-2 ${className} ${
      'disabled' in props && props.disabled === true ? 'cursor-not-allowed' : ''
      }`}
    {...props}
  >
    {children}
  </Button>
)
