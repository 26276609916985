export const isUserLoggedIn = userState => {
  return 'data' in userState &&
    'token' in userState.data &&
    userState.data.token &&
    'user' in userState.data &&
    'clippedOffers' in userState.data.user
    ? true
    : false
}

//to see the thisDeal._id included in the user.cliiper
export const isDealClipped = (clippedOffers, offerId) => {
  return clippedOffers.includes(offerId)
}
