import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import Nav from 'react-bootstrap/Nav'

import { getLanguage, getUser } from '../../../store/data'
import localization from '../../../constants/localization'
import LogoWithSpan from '../LogoWithSpan'
import DownloadLinks from '../DownloadLinks'
import twitter from '../../../assets/images/twitter.svg'
import facebook from '../../../assets/images/facebook.svg'
import instagram from '../../../assets/images/instagram.svg'
import { SignupButton } from '../Button'
import { isUserLoggedIn } from '../../../utils/user'

import { OutboundLink } from "gatsby-plugin-google-analytics"

const menuItems = [
  { name: 'about', to: '/app/about' },
  { name: 'termsNConditions', to: '/app/terms' },
  { name: 'privacy', to: '/app/privacy' },
  { name: 'contact', to: '/app/contact' },
]

export const contactItems = [
  { name: 'customerSupport', mail: 'emailHelp' },
  { name: 'businessPartnerships', mail: 'emailPartners' },
]

const icons = [
  {
    alt: 'twitter',
    src: twitter,
    to: 'https://twitter.com/eclipsa_app',
  },
  {
    alt: 'facebook',
    src: facebook,
    to: 'https://www.facebook.com/eclipsaapp/',
  },
  {
    alt: 'instagram',
    src: instagram,
    to: 'https://www.instagram.com/eclipsa_app',
  },
]

const helpEn = "https://eclipsasupport.zendesk.com/hc/en-us"
const helpFr = "https://eclipsasupport.zendesk.com/hc/fr-ca"

const Separator = () => <span className="text-gray-2 text-xs mx-4">|</span>

const Footer = ({ user, language }) => {
  const isLoggedIn = isUserLoggedIn(user)

  return (
    <div className="bg-gray-1 flex flex-col items-center justify-between pt-20 pb-16">
      {/* logo with Live Better and Save */}
      <LogoWithSpan />

      {/* app & play store */}
      <DownloadLinks />
      {/* signup and navigation links */}
      <Nav className="items-center justify-center">
        {!isLoggedIn && <SignupButton language={language} className="mr-6" />}
        <OutboundLink href={language === 'fr' ? helpFr : helpEn} className="text-gray-2 text-sm mx-4">
          {localization.get(language, 'help')}
        </OutboundLink>

        {menuItems.map(menuItem => (
          <Link
            key={menuItem.to}
            className="text-gray-2 text-sm mx-4"
            to={menuItem.to}
          >
            {localization.get(language, menuItem.name)}
          </Link>
        ))}
      </Nav>

      {/* email addresses */}
      <Nav className="my-10 justify-center items-center">
        <span className="text-white text-xs">@ Eclipsa 2019</span>

        <Separator />

        {contactItems.map((contactItem, contactItemIndex) => (
          <span key={contactItem.mail}>
            <span className="text-white text-xs">
              {localization.get(language, contactItem.name)}
              {':'}
              <a
                className="text-gray-2 text-xs pl-1"
                href={`mailto:${localization.get(language, contactItem.mail)}`}
              >
                {localization.get(language, contactItem.mail)}
              </a>
            </span>

            {contactItemIndex < contactItems.length - 1 && <Separator />}
          </span>
        ))}
      </Nav>

      {/* social media links */}
      <Nav>
        {icons.map(icon => (
          <a
            className="mx-2 transition-all transition-250 hover:opacity-75"
            key={icon.alt}
            href={icon.to}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={icon.src} alt={icon.alt} />
          </a>
        ))}
      </Nav>
    </div>
  )
}

const mapStateToProps = state => ({
  language: getLanguage(state),
  user: getUser(state),
})

export default connect(
  mapStateToProps,
  {}
)(Footer)
