import React from 'react'
import { css } from '@emotion/core'
import { connect } from 'react-redux'
import Nav from 'react-bootstrap/Nav'

import appStoreEn from '../../../assets/images/app-store-en-badge.svg'
import googlePlayEn from '../../../assets/images/google-play-en-badge.svg'
import appStoreFr from '../../../assets/images/app-store-fr-badge.svg'
import googlePlayFr from '../../../assets/images/google-play-fr-badge.svg'

import { getLanguage } from '../../../store/data'

const playStoreEnLink = 'https://play.google.com/store/apps/details?id=com.eclipsa.coupons&hl=en'
const playStoreFrLink = 'https://play.google.com/store/apps/details?id=com.eclipsa.coupons&hl=fr'
const appStoreEnLink = 'https://apps.apple.com/ca/app/eclipsa/id1410401608'
const appStoreFrLink = 'https://apps.apple.com/fr/app/eclipsa/id1410401608'

const DownloadLinks = ({ language }) => {
    const appStores = [
        { alt: 'appStore', src: language == 'fr' ? appStoreFr : appStoreEn, to: language == 'fr' ? appStoreFrLink : appStoreEnLink },
        { alt: 'googlePlay', src: language == 'fr' ? googlePlayFr : googlePlayEn, to: language == 'fr' ? playStoreFrLink : playStoreEnLink },
    ]
    return (
        <Nav className={`mt-12 mb-8 justify-center`}>
            <div
                className="flex"
                css={css`
                min-width: 17rem;
            `}
            >
                {appStores.map(app => (
                    <a
                        className="mx-2"
                        key={app.alt}
                        href={app.to}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={app.src} alt={app.alt} />
                    </a>
                ))}
            </div>
        </Nav>
    )
}

const mapStateToProps = state => ({
    language: getLanguage(state),
})

export default connect(mapStateToProps, {})(DownloadLinks)
